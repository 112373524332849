import { iconClasses } from '../styles';

export function IconArrowLeft({ className, ...props }: IconProps) {
  return (
    <svg
      className={iconClasses({ className })}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4.01 11.98H19' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M10.013 5.98804L4.002 12L10.013 18.012'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
