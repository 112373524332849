'use client';

import Image from 'next/image';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

import { IconArrowLeft } from '@/icons/IconArrowLeft';

export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <div className='flex h-screen flex-col'>
      <div className='mx-auto flex max-w-5xl flex-1 flex-col items-center gap-x-6 px-18 pt-10 sm:flex-row sm:px-20'>
        <div className='mb-6'>
          <Image
            src={{ width: 406, height: 492.07, src: '/images/500.svg' }}
            alt='error message'
          />
        </div>

        <div className='max-w-[25.375rem]'>
          <h2 className='text-lg font-semibold text-blueGray-900'>Error</h2>
          <h1 className='mb-2 text-2xl font-bold text-blueGray-900'>
            Unexpected error
          </h1>
          <p className='text-sm text-blueGray-500'>
            The web server is temporary overloaded and can’t process your
            request. Please accept our apologies for the inconveniences this
            might cause to you.
          </p>
          <p className='mb-8 text-sm font-semibold text-blueGray-500'>
            Please try again in 30 seconds.
          </p>

          <button
            className='flex items-center gap-x-3 text-sm font-semibold text-purpleBlue-600'
            onClick={() => router.replace(pathname)}
          >
            <IconArrowLeft className='stroke-current h-4' />
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
